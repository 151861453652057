import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormTextArea } from 'semantic-ui-react';

function TextAreaInput({ name, label, placeholder, rules = {} }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value }, fieldState }) => (
        <FormTextArea
          inverted
          transparent
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          error={fieldState.error ? true : false}
          required={false}
        />
      )}
    />
  );
}

export default TextAreaInput;
