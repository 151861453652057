import React from 'react';
import { Grid, GridColumn, GridRow, Header, Icon, Segment, HeaderContent, HeaderSubheader, Button } from 'semantic-ui-react';

import './styles.scss';

function ContactCard({
  email,
  address,
  phone
}) {
  return (
    <Segment inverted>
      <Grid columns='equal'>

        <GridRow>
          <GridColumn width={2}>
            <Icon rotated='clockwise' name='phone' size='large' />
          </GridColumn>
          <GridColumn>
            <Header inverted as='h3'>
              <HeaderContent>
                Phone Number
                <HeaderSubheader style={{ fontSize: '1em' }}>{phone}</HeaderSubheader>
              </HeaderContent>
            </Header>
          </GridColumn>
        </GridRow>

        <GridRow>
          <GridColumn width={2}>
            <Icon name='mail' size='large' />
          </GridColumn>
          <GridColumn>
            <Header as='h3' inverted>
              <HeaderContent>
                Email
                <HeaderSubheader style={{ fontSize: '1em' }}>{email}</HeaderSubheader>
              </HeaderContent>
            </Header>
          </GridColumn>
        </GridRow>
        {/* 
        <GridRow>
          <GridColumn width={2}>
            <Icon name='point' size='large' />
          </GridColumn>
          <GridColumn>
            <Header inverted as='h3'>
              <HeaderContent>
                Address
                <HeaderSubheader style={{ fontSize: '1em' }}>{address}</HeaderSubheader>
              </HeaderContent>
            </Header>
          </GridColumn>
        </GridRow> */}
        <GridRow />
      </Grid>
    </Segment>
  );
}

export default ContactCard;

