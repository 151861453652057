import React from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';
import './styles.scss';

function Hero() {
  return (
    <Segment
      inverted
      textAlign='center'
      vertical
      style={{
        minHeight: 700,
        width: "100%",
        padding: '3em 0em 0em 0em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
      <div className='hero-image'>
        <Container style={{ zIndex: '2' }}>
          <Header
            as='h1'
            content='North Coast Creative'
            inverted
            style={{ fontSize: '4em', fontWeight: 'normal', marginBottom: 0, marginTop: '6em' }}
          />
          <Header
            as='h2'
            content='Elevate Your Digital Presence'
            inverted
            style={{ fontSize: '1.7em', fontWeight: 'normal' }}
          />
        </Container>
      </div >
      <div className="hero-overlay"></div>
    </Segment>
  );
}

{/* <Segment
        inverted
        textAlign='center'
        vertical
        style={{
          minHeight: 700,
          width: "100%",
          // padding: '3em 0em 0em 0em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}>
        <Home />

      </Segment> */}

export default Hero;

