import React from 'react';
import { Header } from 'semantic-ui-react';
import './error.scss'; 

function Error() {
    return (
        <div className='error-page'>
            <Header as='h1'>
                Oops! 
                <Header.Subheader>
                  Sorry, an unexpected error occurred. 
                </Header.Subheader>
                <Header as='h4'>Not found</Header>
            </Header>
        </div>
    );
}

export default Error;

