import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Form, Button, Segment, Header } from 'semantic-ui-react';

import TextInput from './components/TextInput';
import TextAreaInput from './components/TextAreaInput';
import { sendEmail } from '../../routes/email';

function ContactForm() {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmission] = useState(false);
  const methods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
    }
  });

  const onSubmit = async (data) => {
    const emailTemplate = {
      user_name: `${data.firstName} ${data.lastName}`,
      user_email: data.email,
      message: data.message
    };

    try {
      setLoading(true);
      sendEmail(emailTemplate);
      methods.reset(); // Reset the form after successful submission.
    } catch (err) {

    } finally {
      setLoading(false);
      setSubmission(true);
    }

  };

  return (
    <FormProvider {...methods}>
      <Segment className="mobile" inverted styl={{ padding: '0em 2em', minHeight: 1200 }}>
        {!submitted ? (
          <Form inverted loading={loading} widths={'equal'} onSubmit={methods.handleSubmit(onSubmit)}>
            <Form.Group>
              <TextInput
                style={{ border: 'solid 1px white' }}
                name="firstName"
                label="First Name"
                placeholder="First name"
                rules={{ required: 'First name is required' }} />
              <TextInput
                name="lastName"
                label="Last Name"
                placeholder="Last name"
                rules={{ required: 'Last name is required' }} />
            </Form.Group>
            <TextInput
              name="email"
              type="email"
              label="Email"
              placeholder="Email"
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^\S+@\S+\.\S+$/,
                  message: 'Invalid email address'
                }
              }} />
            <TextAreaInput
              name="message"
              label="Message"
              placeholder="Send us a message"
              rules={{ required: 'Message is required' }} />
            <Button type='submit' color='orange'>Send Message</Button>
          </Form>
        ) : (
          <Header inverted as='h3' textAlign='center' content='We got your message!' subheader={'We will be in touch soon.'} />
        )}
      </Segment>
    </FormProvider>
  );
}

export default ContactForm;
