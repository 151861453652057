import React from 'react';
import { Container, Menu, Image } from 'semantic-ui-react';
// import { Home, About, Services, Contact } from '../../pages/index.js';
import logo from '../../assets/logo.png'
import './navbar.scss';


function NavBar() {
  return (
    <Menu className='nav' fixed="top" inverted>
      <Container>
        <Menu.Item header>
          <Image src={logo} size='small' />
        </Menu.Item>
        {/* <Menu.Item name='home' />
        <Menu.Item name='about' />
        <Menu.Item name='services' />
        <Menu.Item name='contact' /> */}
      </Container>
    </Menu>
  );
}

export default NavBar;