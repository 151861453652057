import React from 'react';
import { Home } from './pages';
import { NavBar, Footer } from './components/index.js';
import './styles/index.scss';
import 'semantic-ui-css/semantic.min.css';

function App() {
  return (
    <div className="app">
      <NavBar />
      <Home />
      <Footer />
    </div >
  );
}

export default App;

