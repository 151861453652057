import React from 'react';
import { Header } from 'semantic-ui-react';
import './footer.scss';

function Footer() {
  var date = new Date();
  var year = date.getFullYear();
  return (
    <footer className="container">
      <div className='footer-container'>
        <Header as='h4' textAlign='center' styles={{ color: 'white' }}>
          Copyright © {year} North Coast Creative LLC
        </Header>
        {/* <small>
          <a href="#">Privacy Policy</a> • <a href="#">Terms of Use</a>
        </small> */}
      </div>
    </footer>
  );
}

export default Footer;
