import React from 'react';
import { Grid, GridColumn, GridRow, Segment, Header } from 'semantic-ui-react';
import { ContactForm, ContactCard } from '../../../components';

export const CONTACT_COPY = {
  phone: "(971) 702-2031",
  address: "818 Commercial ",
  email: "info@northcoastcreative.agency"
}

function ContactSection() {
  return (
    <Segment
      className='mobile'
      style={{ padding: '0 4em' }}
      inverted
      vertical>
      <Grid stackable>

        <GridRow>
          <GridColumn width={5}>
            <ContactCard email={CONTACT_COPY.email} address={CONTACT_COPY.address} phone={CONTACT_COPY.phone} />
          </GridColumn>

          <GridColumn width={10} textAlign='left'>
            <ContactForm />
          </GridColumn>
        </GridRow>
      </Grid>
    </Segment >
  );
}

export default ContactSection;