import React from 'react';
import { Segment, Container, Header } from 'semantic-ui-react';
import './styles.scss';


import AboutSection from './sections/AboutSection';
import ContactSection from './sections/ContactSection';
import ServicesSection from './sections/ServicesSection';
import { Hero } from '../../components';
// import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

function Home() {
  const colorGreen = '#9fa991';
  const pTagStyle = { fontSize: '1.33em' };

  const pageMarginContentStyle = { margin: '0em 4em', padding: '3em 0em' }
  const fullWidthContentStyle = { padding: '6em 0em' }
  return (
    <>
      <Hero />
      <Segment className='content-container' inverted style={pageMarginContentStyle} vertical>
        <AboutSection />
      </Segment>
      <Segment className='content-container' inverted style={{ ...fullWidthContentStyle, backgroundColor: colorGreen }} vertical>
        <Container textAlign='center' className="content-block" >
          <Header inverted as='h2' textAlign='center' content='Join Us on a Creative Voyage' style={{ fontSize: '2em' }} />
          <p style={pTagStyle} >Together, let's embark on this creative voyage and elevate your online presence. Whether you're looking to enhance your website's functionality or boost its visual appeal, we're here to guide and support you every step of the way.</p>
        </Container>
      </Segment >
      {/* <Segment inverted style={pageMarginContentStyle} vertical>
        <ServicesSection />
      </Segment> */}
      <Segment className='content-container' inverted style={pageMarginContentStyle} vertical>
        <ContactSection />
      </Segment>
    </>
  );
}

export default Home;