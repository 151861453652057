import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormInput, Input } from 'semantic-ui-react';


const TextInputElemenet = (props) => <Input {...props} fluid inverted transparent />


function TextInput({ name, label, type = "text", placeholder, rules = {} }) {
  const { control } = useFormContext(); // Use form context
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value }, fieldState }) => (
        <FormInput
          inverted
          transparent
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          error={fieldState.error ? true : false}
          required
          size='large'
        // fluid
        />
      )}
    />
  );
}

export default TextInput;
