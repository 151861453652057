import emailjs from '@emailjs/browser';

export const sendEmail = (data) => {
  emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, data, process.env.REACT_APP_PUBKEY)
    .then((res) => {
      console.log(res);
    }, (err) => {
      console.log(err);
    });
};


